import { Environment } from "../../types/Misc.type";
import {allLocales, BRAZIL_LOCALES, COLOMBIA_LOCALES, GERMANY_LOCALES, LANGUAGE_COUNTRY, MEXICO_LOCALES} from "./LocaleAndCountryConstants";

export const DEVELOPMENT = "development";
export const PRODUCTION = "production";
export const DOWNLOAD_PRODUCTION = "download_prod";
export const WINZO_SPORTS_STAG = "winzo_sports_stag";
export const WINZO_SPORTS_PROD = "winzo_sports_prod";
export const WINZO_DOT_GAMES_PROD = "winzo_games_prod";
export const WINZO_SUPERSTAR_PROD = "winzo_superstar_prod";
export const STAG_WEB_URL = "stag.winzogames.com";
export const LIVE_WEB_URL = "www.winzogames.com";
export const DOWNLOAD_WEB_URL = "download.winzogames.com";
export const WINZO_SPORT_URL = "www.winzosports.com";
export const WINZO_CONSOLE_URL = "console.winzogames.com"
export const WINZO_DOT_GAMES_URL = "winzo.games"
export const WINZO_GLOBAL_URL = "www.winzoglobal.com"
export const WINZO_SUPERSTAR_URL = "www.winzosuperstar.com"
export const WINZO_GAMES_DOT_US = "www.winzogames.us"
export const BRAND_DOT_WINZOGAMES = "brand.winzogames.com"
export const WINZO_DOT_APP = "www.winzo.app"
export const GAMESMANIA_DOT_IN = "www.gamesmania.in"
export const WINFANTASY_DOT_IN = "www.winfantasy.in"
export const WINZO_JOGOS_DOT_COM_BR = "www.winzojogos.com.br"
export const THE_TECH_TRIUMPH = "www.thetechtriumph.com"
export const WINZO_JUEGOS_DOT_MX = "www.winzojuegos.mx"
export const WINZO_JUEGOS_DOT_COM_CO = "www.winzojuegos.com.co"
export const WINZO_SPIELE_DOT_DE = "www.winzospiele.de"

const STAG_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang-stag",
    baseFolder: "website",
    gtmId: "GTM-K3VT27J",
    baseUrl: STAG_WEB_URL,
    supportedLanguages: allLocales,
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const PROD_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-WKJX3LD",
    baseUrl: LIVE_WEB_URL,
    supportedLanguages: allLocales,
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: true
}

const DOWNLOAD_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang-download",
    baseFolder: "website",
    gtmId: "GTM-WKJX3LD",
    baseUrl: DOWNLOAD_WEB_URL,
    supportedLanguages: allLocales,
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const STAG_SPORTS_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang-stag",
    baseFolder: "website",
    gtmId: "GTM-K3VT27J",
    baseUrl: WINZO_SPORT_URL,
    supportedLanguages: allLocales,
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const PROD_SPORTS_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-N99MJV5",
    baseUrl: WINZO_SPORT_URL,
    supportedLanguages: [LANGUAGE_COUNTRY.PT_BR],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const PROD_WINZO_GAMES_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-NBJSJ55V",
    baseUrl: WINZO_DOT_GAMES_URL,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_IN],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const PROD_GLOBAL_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-PXF58L6",
    baseUrl: WINZO_GLOBAL_URL,
    supportedLanguages: allLocales,
    defaultLocale: LANGUAGE_COUNTRY.EN_US,
    shouldIndex: true
}

const PROD_SUPERSTAR_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-KMZF7PQ",
    baseUrl: WINZO_SUPERSTAR_URL,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_IN],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const PROD_DOT_US_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-K523RS2T",
    baseUrl: WINZO_GAMES_DOT_US,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_US],
    defaultLocale: LANGUAGE_COUNTRY.EN_US,
    shouldIndex: true
}

const BRAND_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-WKJX3LD",
    baseUrl: BRAND_DOT_WINZOGAMES,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_IN],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: true
}

const WINZO_DOT_APP_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-NM3DNFCP",
    baseUrl: WINZO_DOT_APP,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_IN],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const GAMESMANIA_DOT_IN_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-K3K6F9VD",
    baseUrl: GAMESMANIA_DOT_IN,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_IN],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const WINFANTASY_DOT_IN_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-NM3DNFCP",
    baseUrl: WINFANTASY_DOT_IN,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_IN],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: false
}

const WINZO_JOGOS_BR_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-W4VCZ5XP",
    baseUrl: WINZO_JOGOS_DOT_COM_BR,
    supportedLanguages: BRAZIL_LOCALES,
    defaultLocale: LANGUAGE_COUNTRY.PT_BR,
    shouldIndex: true
}

const THE_TECH_TRIUMPH_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-5RBB2GFM",
    baseUrl: THE_TECH_TRIUMPH,
    supportedLanguages: [LANGUAGE_COUNTRY.EN_IN],
    defaultLocale: LANGUAGE_COUNTRY.EN_IN,
    shouldIndex: true
}

const WINZO_JUEGOS_DOT_MX_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-THZV46GS",
    baseUrl: WINZO_JUEGOS_DOT_MX,
    supportedLanguages: MEXICO_LOCALES,
    defaultLocale: LANGUAGE_COUNTRY.ES_MX,
    shouldIndex: true
}

const WINZO_JUEGOS_DOT_COM_CO_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-5WX26933",
    baseUrl: WINZO_JUEGOS_DOT_COM_CO,
    supportedLanguages: COLOMBIA_LOCALES,
    defaultLocale: LANGUAGE_COUNTRY.ES_CO,
    shouldIndex: true
}

const WINZO_SPIELE_DOT_DE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-WR63N2QB",
    baseUrl: WINZO_SPIELE_DOT_DE,
    supportedLanguages: GERMANY_LOCALES,
    defaultLocale: LANGUAGE_COUNTRY.DE_DE,
    shouldIndex: true
}

export const getWebsiteEnvironment = (domain: string = process.env.NEXT_PUBLIC_URL!): Environment => {
    if (domain === `https://${STAG_WEB_URL}/`)
        return STAG_WEBSITE_ENV;
    else if (domain === `https://${WINZO_GLOBAL_URL}/`)
        return PROD_GLOBAL_WEBSITE_ENV;
    else if (domain === `https://${WINZO_GAMES_DOT_US}/`)
        return PROD_DOT_US_WEBSITE_ENV;
    else if (domain === `https://${BRAND_DOT_WINZOGAMES}/`)
        return BRAND_WEBSITE_ENV;
    else if (domain === `https://${WINZO_DOT_APP}/`)
        return WINZO_DOT_APP_WEBSITE_ENV;
    else if (domain === `https://${WINFANTASY_DOT_IN}/`)
        return WINFANTASY_DOT_IN_WEBSITE_ENV;
    else if (domain === `https://${GAMESMANIA_DOT_IN}/`)
        return GAMESMANIA_DOT_IN_WEBSITE_ENV;
    else if (domain === `https://${WINZO_JOGOS_DOT_COM_BR}/`)
        return WINZO_JOGOS_BR_ENV;
    else if (domain === `https://${THE_TECH_TRIUMPH}/`)
        return THE_TECH_TRIUMPH_ENV;
    else if (domain === `https://${WINZO_JUEGOS_DOT_MX}/`)
        return WINZO_JUEGOS_DOT_MX_ENV;
    else if (domain === `https://${WINZO_JUEGOS_DOT_COM_CO}/`)
        return WINZO_JUEGOS_DOT_COM_CO_ENV;
    else if (domain === `https://${WINZO_SPIELE_DOT_DE}/`)
        return WINZO_SPIELE_DOT_DE_ENV;
    else if (domain === `https://${LIVE_WEB_URL}/`)
        return PROD_WEBSITE_ENV;
    else if (domain === `https://${DOWNLOAD_WEB_URL}/`)
        return DOWNLOAD_WEBSITE_ENV;
    else if (domain === `https://${WINZO_SPORT_URL}/`)
        return PROD_SPORTS_WEBSITE_ENV;
    else if (domain === `https://${WINZO_DOT_GAMES_URL}/`)
        return PROD_WINZO_GAMES_WEBSITE_ENV;
    else if (domain === `https://${WINZO_SUPERSTAR_URL}/`)
        return PROD_SUPERSTAR_WEBSITE_ENV;
    else
        return PROD_WEBSITE_ENV;
}

export const isDevelopmentEnv = process.env.NEXT_PUBLIC_ENV === "development";


