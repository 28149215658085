// All languages object
export const LANGUAGE = {
    ENGLISH: "en",
    HINDI: "hi",
    GUJARATI: "gu",
    BENGALI: "bn",
    TAMIL: "ta",
    MARATHI: "mr",
    TELUGU: "te",
    KANNADA: "kn",
    PUNJABI: "pa",
    MALAYALAM: "ml",
    PORTUGUESE: "pt",
    SPANISH: "es",
    FRENCH: "fr",
    GERMAN: "de",
}

// All country object
export const COUNTRY = {
    INDIA: "in",
    US: "us",
    BRAZIL: "br",
    MEXICO: "mx",
    UNITED_KINGDOM: "gb",
    CANADA: "ca",
    SOUTH_AFRICA: "za",
    NIGERIA: "ng",
    COLOMBIA: "co",
    GERMANY: "de"
}

// LANGUAGE + COUNTRY CONSTANT
export const LANGUAGE_COUNTRY = {
    /** {@link COUNTRY.INDIA} **/
    EN_IN: `${LANGUAGE.ENGLISH}-${COUNTRY.INDIA}`,
    HI_IN: `${LANGUAGE.HINDI}-${COUNTRY.INDIA}`,
    GU_IN: `${LANGUAGE.GUJARATI}-${COUNTRY.INDIA}`,
    BN_IN: `${LANGUAGE.BENGALI}-${COUNTRY.INDIA}`,
    TA_IN: `${LANGUAGE.TAMIL}-${COUNTRY.INDIA}`,
    MR_IN: `${LANGUAGE.MARATHI}-${COUNTRY.INDIA}`,
    TE_IN: `${LANGUAGE.TELUGU}-${COUNTRY.INDIA}`,
    KN_IN: `${LANGUAGE.KANNADA}-${COUNTRY.INDIA}`,
    PA_IN: `${LANGUAGE.PUNJABI}-${COUNTRY.INDIA}`,
    ML_IN: `${LANGUAGE.MALAYALAM}-${COUNTRY.INDIA}`,

    /** {@link COUNTRY.US} **/
    EN_US: `${LANGUAGE.ENGLISH}-${COUNTRY.US}`,

    /** {@link COUNTRY.GERMANY} **/
    EN_DE: `${LANGUAGE.ENGLISH}-${COUNTRY.GERMANY}`,
    DE_DE: `${LANGUAGE.GERMAN}-${COUNTRY.GERMANY}`,

    /** {@link COUNTRY.BRAZIL} **/
    EN_BR: `${LANGUAGE.ENGLISH}-${COUNTRY.BRAZIL}`,
    PT_BR: `${LANGUAGE.PORTUGUESE}-${COUNTRY.BRAZIL}`,

    /** {@link COUNTRY.MEXICO} **/
    EN_MX:  `${LANGUAGE.ENGLISH}-${COUNTRY.MEXICO}`,
    ES_MX:  `${LANGUAGE.SPANISH}-${COUNTRY.MEXICO}`,

    /** {@link COUNTRY.UNITED_KINGDOM} **/
    EN_GB:  `${LANGUAGE.ENGLISH}-${COUNTRY.UNITED_KINGDOM}`,

    /** {@link COUNTRY.CANADA} **/
    EN_CA:  `${LANGUAGE.ENGLISH}-${COUNTRY.CANADA}`,
    FR_CA:  `${LANGUAGE.FRENCH}-${COUNTRY.CANADA}`,

    /** {@link COUNTRY.SOUTH_AFRICA} **/
    EN_ZA:  `${LANGUAGE.ENGLISH}-${COUNTRY.SOUTH_AFRICA}`,

    /** {@link COUNTRY.NIGERIA} **/
    EN_NG:  `${LANGUAGE.ENGLISH}-${COUNTRY.NIGERIA}`,

    /** {@link COUNTRY.COLOMBIA} **/
    EN_CO:  `${LANGUAGE.ENGLISH}-${COUNTRY.COLOMBIA}`,
    ES_CO:  `${LANGUAGE.SPANISH}-${COUNTRY.COLOMBIA}`
}

// ALL LANGUAGE + COUNTRY ARRAY
export const allLocales : string[] = Object.values(LANGUAGE_COUNTRY);
export const localeArrayMinLanguage = [LANGUAGE_COUNTRY.EN_IN, LANGUAGE_COUNTRY.HI_IN];

const getCountryLocales = (country: string): Array<string> => {
    return allLocales.filter(item => item.includes(country))
}

// ALL INDIAN LANGUAGES ARRAY
export const INDIA_LOCALES = getCountryLocales(COUNTRY.INDIA);
export const US_LOCALES = getCountryLocales(COUNTRY.US);
export const GERMANY_LOCALES = getCountryLocales(COUNTRY.GERMANY);
export const BRAZIL_LOCALES = getCountryLocales(COUNTRY.BRAZIL);
export const MEXICO_LOCALES = getCountryLocales(COUNTRY.MEXICO);
export const UNITED_KINGDOM_LOCALES = getCountryLocales(COUNTRY.UNITED_KINGDOM);
export const CANADA_LOCALES = getCountryLocales(COUNTRY.CANADA);
export const SOUTH_AFRICA_LOCALES = getCountryLocales(COUNTRY.SOUTH_AFRICA);
export const NIGERIA_LOCALES = getCountryLocales(COUNTRY.NIGERIA);
export const COLOMBIA_LOCALES = getCountryLocales(COUNTRY.COLOMBIA);

export const languageTextMapping = new Map<string, string>([
    [LANGUAGE.ENGLISH, "ENGLISH"],
    [LANGUAGE.HINDI, "हिन्दी"],
    [LANGUAGE.MARATHI, "मराठी"],
    [LANGUAGE.TELUGU, "తెలుగు"],
    [LANGUAGE.GUJARATI, "ગુજરાતી"],
    [LANGUAGE.BENGALI, "বাংলা"],
    [LANGUAGE.KANNADA, "ಕನ್ನಡ"],
    [LANGUAGE.PUNJABI, "ਪੰਜਾਬੀ"],
    [LANGUAGE.TAMIL, "தமிழ்"],
    [LANGUAGE.MALAYALAM, "മലയാള"],
    [LANGUAGE.PORTUGUESE, "PORTUGUÊS"],
    [LANGUAGE.SPANISH, "español"],
    [LANGUAGE.FRENCH, "française"],
    [LANGUAGE.GERMAN, "DEUTSCH"]
])

export const countryTextMapping = new Map<string, string>([
    [COUNTRY.INDIA, "INDIA"],
    [COUNTRY.US, "USA"],
    [COUNTRY.GERMANY, "GERMANY"],
    [COUNTRY.BRAZIL, "BRAZIL"],
    [COUNTRY.UNITED_KINGDOM, "UNITED KINGDOM"],
    [COUNTRY.CANADA, "CANADA"],
    [COUNTRY.SOUTH_AFRICA, "SOUTH AFRICA"],
    [COUNTRY.NIGERIA, "NIGERIA"],
    [COUNTRY.COLOMBIA, "COLOMBIA"]
])

export const defaultLocaleByCountry = new Map<string, string>([
    [COUNTRY.INDIA, LANGUAGE_COUNTRY.EN_IN],
    [COUNTRY.US, LANGUAGE_COUNTRY.EN_US],
    [COUNTRY.GERMANY, LANGUAGE_COUNTRY.DE_DE],
    [COUNTRY.BRAZIL, LANGUAGE_COUNTRY.PT_BR],
    [COUNTRY.UNITED_KINGDOM, LANGUAGE_COUNTRY.EN_GB],
    [COUNTRY.CANADA, LANGUAGE_COUNTRY.FR_CA],
    [COUNTRY.SOUTH_AFRICA, LANGUAGE_COUNTRY.EN_ZA],
    [COUNTRY.NIGERIA, LANGUAGE_COUNTRY.DE_DE],
    [COUNTRY.COLOMBIA, LANGUAGE_COUNTRY.EN_NG],
    [COUNTRY.MEXICO, LANGUAGE_COUNTRY.ES_MX]
])